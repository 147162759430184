import './TypeAhead.css';
import PropTypes from 'prop-types';
import { useState } from 'react';

const TypeAhead = ({
  options,
  currentSearchTerm,
  activeOption,
  onActiveChange,
  onSearchTermChange
}) => {
  const [filteredOptions, setFilteredOptions] = useState([...options]);
  const [showOptionList, setShowOptionList] = useState(false);
  const filterOptions = (searchTerm) => [...options].filter((opt) => opt.text.includes(searchTerm));

  const handleActiveOption = (event) => {
    onActiveChange(event);
    setShowOptionList(false);
  };

  const searchHandler = (event) => {
    onSearchTermChange(event);

    if (event.target.value !== '') {
      setFilteredOptions(filterOptions(event.target.value));
      setShowOptionList(true);
    } else {
      setShowOptionList(false);
    }
  };

  const blurHandler = (event) => {
    if (
      event.relatedTarget !== undefined &&
      event.relatedTarget !== null &&
      event.relatedTarget.type === 'button' &&
      event.relatedTarget.classList !== undefined &&
      event.relatedTarget.classList !== null &&
      event.relatedTarget.classList.contains('optionlist-item')
    )
      return;

    setShowOptionList(false);
  };

  const focusHandler = () => {
    if (currentSearchTerm !== '') {
      setFilteredOptions(filterOptions(currentSearchTerm));
      setShowOptionList(true);
    }
  };

  const buttonClickHandler = () => {
    if (!showOptionList) {
      setFilteredOptions([...options]);
      setShowOptionList(true);
    } else setShowOptionList(false);
  };

  const buttonBlurHandler = (event) => {
    //Hide Option List unless the element the user jumped to is either the text input or an option from the list
    if (
      event.relatedTarget !== undefined &&
      event.relatedTarget !== null &&
      ((event.relatedTarget.type === 'button' &&
        event.relatedTarget.classList !== undefined &&
        event.relatedTarget.classList !== null &&
        event.relatedTarget.classList.contains('optionlist-item')) ||
        (event.relatedTarget.type === 'text' &&
          event.relatedTarget.classList !== undefined &&
          event.relatedTarget.classList !== null &&
          event.relatedTarget.classList.contains('typeahead-input')))
    )
      return;

    setShowOptionList(false);
  };

  return (
    <div className="typeahead-container">
      <input
        type="text"
        className={'typeahead-input' + (showOptionList ? ' typeahead-input-active' : '')}
        placeholder="Type or click the arrow to see questions"
        title="Type or click the arrow to see questions"
        onChange={searchHandler}
        onBlur={blurHandler}
        onFocus={focusHandler}
        value={currentSearchTerm}
      />
      <button
        className={
          'typeahead-button bi bi-chevron-down arrow' +
          (showOptionList ? ' typeahead-button-active' : '')
        }
        type="button"
        onClick={buttonClickHandler}
        onBlur={buttonBlurHandler}></button>
      <div
        className={'typeahead-optionlist' + (showOptionList ? ' typeahead-optionlist-active' : '')}>
        {filteredOptions.map((opt) => (
          <button
            key={'btn' + opt.value}
            className={
              'optionlist-item' + (opt.value === activeOption ? ' optionlist-item-selected' : '')
            }
            type="button"
            value={opt.value}
            title={opt.title}
            onClick={handleActiveOption}>
            {opt.text}
          </button>
        ))}
      </div>
    </div>
  );
};

TypeAhead.propTypes = {
  highlightSearch: PropTypes.bool,
  options: PropTypes.array.isRequired,
  currentSearchTerm: PropTypes.string.isRequired,
  activeOption: PropTypes.string,
  onActiveChange: PropTypes.func.isRequired,
  onSearchTermChange: PropTypes.func.isRequired
};

export default TypeAhead;
