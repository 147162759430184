import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import DataGrid from 'react-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'react-data-grid/lib/styles.css';

//Maps responses to rows for the grid
function createRows(responses) {
  const rows = [];

  if (responses.length > 0) {
    responses.forEach((response) => {
      rows.push({
        ...response,
        responseText: response.responseText ?? '',
        punch: response.punch ?? 0,
        min: response.low ?? 0,
        max: response.high ?? 0,
        isUpdated: false, // Flag used to check which rows have been updated
        errors: { responseText: '', min: '', max: '' }
      });
    });
  } else {
    rows.push({
      responseText: '0',
      punch: '0',
      min: '0',
      max: '1',
      errors: { responseText: '', min: [], max: [] }
    });
  }

  return rows;
}

function createColumns(rows, setRows) {
  return [
    {
      key: 'responseText',
      name: 'Response Text',
      //using formatter to create an input field to allow the user to edit the response text
      //Columns also have a editor configuration option but it is not as customizable
      formatter: (p) => renderInput(p, setRows, 'text', 'responseText', p.row.errors.responseText),
      summaryFormatter() {
        return <>Range</>;
      }
    },
    {
      key: 'min',
      name: 'Min',
      width: 100,
      formatter: (p) => renderInput(p, setRows, 'number', 'min', p.row.errors.min),
      summaryFormatter({ row }) {
        return <>{row.min}</>;
      }
    },
    {
      key: 'max',
      name: 'Max',
      width: 100,
      formatter: (p) => renderInput(p, setRows, 'number', 'max', p.row.errors.max),
      summaryFormatter({ row }) {
        return <>{row.max}</>;
      }
    },
    { key: 'punch', name: 'Value', width: 100 },
    {
      key: 'addRemove',
      name: 'Add / Remove',
      width: 120,
      formatter: (p) => (
        <>
          <a
            data-tooltip-id="QNRCenterPanelToolTip"
            data-tooltip-content={'Insert Row Below ' + p.row.responseText}
            data-tooltip-place="top"
            data-tooltip-delay-show="500"
            className="tool-tip-anchor"
            onClick={() =>
              setRows((prevRows) => {
                return addNewRange([...prevRows], p.row.punch);
              })
            }>
            <FontAwesomeIcon icon="fa-solid fa-circle-plus" color="#00a0af" />
          </a>
          <a
            data-tooltip-id="QNRCenterPanelToolTip"
            data-tooltip-content={
              rows.length === 1 ? 'Unavailable' : 'Remove ' + p.row.responseText
            }
            data-tooltip-place="top"
            data-tooltip-variant={rows.length === 1 ? 'dark' : 'error'}
            data-tooltip-delay-show="500"
            className="tool-tip-anchor"
            onClick={
              rows.length > 1
                ? () => {
                    setRows((prevRows) => {
                      return removeRange(prevRows, p.row.punch);
                    });
                  }
                : ''
            }>
            <FontAwesomeIcon
              icon="fa-solid fa-circle-minus"
              color={rows.length === 1 ? '#99d9df' : '#00a0af'}
            />
          </a>
        </>
      )
    }
  ];
}

function renderInput(p, setRows, inputType, key, error) {
  const input = (
    <input
      type={inputType}
      className={'rdg-cell-input' + (error.length > 0 ? ' error-input' : '')}
      value={p.row[key]}
      onChange={(e) => p.onRowChange({ ...p.row, [key]: e.target.value, isUpdated: true }, true)}
      onBlur={(e) => {
        if (inputType === 'number') {
          setRows((prevRows) => {
            return prevRows.map((row) => {
              if (row.punch === p.row.punch - 1 && key === 'min') {
                return {
                  ...row,
                  errors: {
                    ...row.errors,
                    max: validateRangeMinMax('max', row.min, row.max, p.row.min)
                  }
                };
              } else if (row.punch === p.row.punch + 1 && key === 'max') {
                return {
                  ...row,
                  errors: {
                    ...row.errors,
                    min: validateRangeMinMax('min', row.min, row.max, p.row.max)
                  }
                };
              } else if (row.punch === p.row.punch) {
                return {
                  ...row,
                  errors: {
                    ...row.errors,
                    min: validateRangeMinMax(
                      'min',
                      row.min,
                      row.max,
                      row.punch > 0 ? prevRows[row.punch - 1].max : row.min
                    ),
                    max: validateRangeMinMax(
                      'max',
                      row.min,
                      row.max,
                      row.punch < prevRows.length - 1 ? prevRows[row.punch + 1].min : row.max
                    )
                  }
                };
              } else {
                return row;
              }
            });
          });
        } else if (inputType === 'text') {
          let error = e.target.value === '' ? 'Response Text is required' : '';
          if (error !== p.row.errors.responseText) {
            p.onRowChange({ ...p.row, errors: { ...p.row.errors, responseText: error } }, true);
          }
        }
      }}></input>
  );
  const tooltip = (
    <a
      data-tooltip-id="QNRCenterPanelToolTip"
      data-tooltip-content={error}
      data-tooltip-place="top"
      data-tooltip-variant="error">
      {input}
    </a>
  );
  return error.length > 0 ? tooltip : input;
}

function addNewRange(rows, addPunch) {
  rows.splice(addPunch + 1, 0, {
    responseText: rows[addPunch].max,
    punch: -1,
    min: rows[addPunch].max,
    max: addPunch !== rows.length - 1 ? rows[addPunch + 1].min : parseFloat(rows[addPunch].max + 1),
    errors: {
      responseText: '',
      min: validateRangeMinMax(
        'min',
        rows[addPunch].max,
        addPunch !== rows.length - 1 ? rows[addPunch + 1].min : parseFloat(rows[addPunch].max + 1),
        rows[addPunch].max
      ),
      max: validateRangeMinMax(
        'max',
        rows[addPunch].max,
        addPunch !== rows.length - 1 ? rows[addPunch + 1].min : parseFloat(rows[addPunch].max + 1),
        addPunch !== rows.length - 1 ? rows[addPunch + 1].min : parseFloat(rows[addPunch].max + 1)
      )
    }
  });
  return rows.map((row) => {
    if (row.punch > addPunch) {
      if (row.punch === addPunch + 1) {
        return {
          ...row,
          punch: row.punch + 1,
          errors: {
            ...row.errors,
            min: validateRangeMinMax('min', row.min, row.max, rows[addPunch + 1].max)
          }
        };
      }
      return {
        ...row,
        punch: row.punch + 1
      };
    } else {
      if (row.punch === addPunch) {
        return {
          ...row,
          errors: {
            ...row.errors,
            max: validateRangeMinMax('max', row.min, row.max, rows[addPunch + 1].min)
          }
        };
      } else if (row.punch === -1) {
        return { ...row, punch: addPunch + 1 };
      }
      return row;
    }
  });
}

function removeRange(rows, delPunch) {
  return rows
    .filter((row) => row.punch !== delPunch)
    .map((row) => {
      if (row.punch < delPunch) {
        if (row.punch === delPunch - 1) {
          return {
            ...row,
            errors: {
              ...row.errors,
              max: validateRangeMinMax(
                'max',
                row.min,
                row.max,
                delPunch !== rows.length - 1 ? rows[row.punch + 2].min : row.max
              )
            }
          };
        } else {
          return row;
        }
      } else if (row.punch > delPunch) {
        if (row.punch === delPunch + 1) {
          return {
            ...row,
            punch: row.punch - 1,
            errors: {
              ...row.errors,
              min: validateRangeMinMax(
                'min',
                row.min,
                row.max,
                delPunch !== 0 ? rows[row.punch - 2].max : row.min
              )
            }
          };
        } else {
          return {
            ...row,
            punch: row.punch - 1
          };
        }
      }
    });
}

function getMinMaxFromRows(rows) {
  let min = 0;
  let max = 0;
  for (let i = 0; i < rows.length; i++) {
    console.log(rows[i]);
    if (i === 0 || rows[i].min < min) {
      min = rows[i].min;
    }
    if (i === 0 || rows[i].max > max) {
      max = rows[i].max;
    }
  }
  return { min, max };
}

function validateRangeMinMax(input, currentMin, currentMax, minMax) {
  let error = '';
  if (input === 'min') {
    if (currentMin < minMax) {
      error = ' | Overlap ' + currentMin + '-' + minMax;
    }
    if (currentMin > currentMax) {
      error += ' | Min must be less than Max';
    }
    if (currentMin > minMax) {
      error += ' | Missing ' + minMax + '-' + currentMin;
    }
  } else if (input === 'max') {
    if (currentMax > minMax) {
      error += ' | Overlap ' + minMax + '-' + currentMax;
    }
    if (currentMax < currentMin) {
      error += ' | Max must be greater than Min';
    }
    if (currentMax < minMax) {
      error += ' | Missing ' + currentMax + '-' + minMax;
    }
  }
  if (currentMin === currentMax) {
    error += ' | Min and Max must be different';
  }
  return error === '' ? '' : error.substring(3);
}

//Optional but recommended for performance
function rowKeyGetter(row) {
  return row.cxChoiceGuid;
}

function NumericRangesDataGrid({ responses }) {
  const [rows, setRows] = useState(createRows(responses));

  const columns = createColumns(rows, setRows);

  const summaryRows = useMemo(() => {
    console.log('summaryRows');
    const { min, max } = getMinMaxFromRows(rows);
    const summaryRow = {
      id: 'rangeSummary',
      min: min,
      max: max
    };
    return [summaryRow];
  }, [rows]);

  return (
    <div className="qnr-center-panel">
      <DataGrid
        columns={columns}
        rows={rows}
        onRowsChange={setRows}
        className="rdg-light"
        rowKeyGetter={rowKeyGetter}
        bottomSummaryRows={summaryRows}
      />
      <div className="button-row">
        <button className="btn dl-primary-button">Update question and responses</button>
      </div>
    </div>
  );
}

NumericRangesDataGrid.propTypes = {
  responses: PropTypes.array.isRequired,
  saveQuestionAndResponsesHandler: PropTypes.func.isRequired
};

export default NumericRangesDataGrid;
