export const displayFormatOptions = [
  { value: 0, label: 'No/Default Format' },
  { value: 1, label: 'Punch (0) or KW Response Text' },
  { value: 2, label: 'Punch (0.0) or KW Response Text' },
  { value: 3, label: 'Punch (0.00) or KW Response Text' },
  { value: 4, label: 'Punch (0) or (DK, REF, NA)' },
  { value: 5, label: 'Punch (0.0) or (DK, REF, NA)' },
  { value: 6, label: 'Punch (0.00) or  (DK, REF, NA)' },
  { value: 7, label: 'ResponseText' },
  { value: 8, label: 'Numeric 0' },
  { value: 9, label: 'Numeric 0.0' },
  { value: 10, label: 'Numeric 0.00' },
  { value: 11, label: 'Numeric 0.000' },
  { value: 12, label: 'Numeric 0.0000' },
  { value: 13, label: 'Numeric 0.00000' },
  { value: 14, label: 'Fix as is' },
  { value: 15, label: 'Fix as phone' },
  { value: 16, label: 'Fix as Date' },
  { value: 17, label: 'Fix as Time' },
  { value: 18, label: 'Fix as DateTime' },
  { value: 19, label: 'OE Text, no keywords' },
  { value: 20, label: 'OE Text or KW ResponseText' },
  { value: 21, label: 'OE Text or KW (DK, REF, NA)' },
  { value: 22, label: 'Pct 0% x100' },
  { value: 23, label: 'Pct 0.0% x100' },
  { value: 24, label: 'Pct 0.00% x100' },
  { value: 25, label: 'Pct 0.000% x100' },
  { value: 27, label: 'Pct 0% As Is' },
  { value: 28, label: 'Pct 0.0%   As Is' },
  { value: 29, label: 'Pct 0.00%   As Is' },
  { value: 30, label: 'Pct 0.000%   As Is' },
  { value: 31, label: 'Punches Only (DK=1,REF=2,NA=3)/altpunch' },
  { value: 35, label: 'Numeric 0.000000' },
  { value: 36, label: 'Numeric 0.0000000' },
  { value: 100, label: 'Fix Text As Image (jpg)' },
  { value: 101, label: 'Fix Text As Image (png)' },
  { value: 102, label: 'Response as image' },
  { value: 103, label: 'Fix as hyperlink' },
  { value: 104, label: 'Link to Report' },
  { value: 110, label: 'Fix as elapsed days 0.0' },
  { value: 111, label: 'Fix as elapsed days 0' },
  { value: 112, label: 'Fix as elapsed hours 0.0' },
  { value: 113, label: 'Fix as elapsed hours 0.00' },
  { value: 114, label: 'Fix as elapsed minutes 0' },
  { value: 204, label: 'Text (was called AsIs)' },
  { value: 205, label: 'AltText' },
  { value: 206, label: 'Phone' },
  { value: 207, label: 'DateFmt' },
  { value: 400, label: 'TimeFmt' },
  { value: 523, label: 'DateTimeFmt' }
];
