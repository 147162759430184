import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomNavigationClient } from '../../config/msal/routesConfig';
import { LOGIN_SCOPES } from '../../config/openIdConfig';
import { InteractionType } from '@azure/msal-browser';
import Loading from '../Shared/Loading';
import PropTypes from 'prop-types';

const Login = ({ children }) => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const navigator = new CustomNavigationClient(navigate);
  instance.setNavigationClient(navigator);

  const [searchParams] = useSearchParams();

  const domainHint = searchParams.get('idp');
  const loginHint = searchParams.get('hint');

  const loginRequest = {
    scopes: LOGIN_SCOPES,
    domainHint: domainHint,
    loginHint: loginHint
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      loadingComponent={Loading}>
      {children}
    </MsalAuthenticationTemplate>
  );
};

Login.propTypes = {
  children: PropTypes.node.isRequired
};
export default Login;
