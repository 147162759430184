import { getRequest, patchRequest } from '../utils/requestBuilder';

export default class SurveyMapService {
  constructor(getAccessTokenFunc) {
    this.getAccessToken = getAccessTokenFunc;
  }

  getSurveyMap(workspaceGuid) {
    return this.getAccessToken()
      .then((accessToken) => getRequest(`/api/surveymaps/${workspaceGuid}`, accessToken))
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        } else {
          return response.json();
        }
      });
  }

  getSingleSurveyMap(workspaceGuid, surveyMapPublicKey) {
    return this.getAccessToken()
      .then((accessToken) =>
        getRequest(`/api/surveymaps/${workspaceGuid}/${surveyMapPublicKey}`, accessToken)
      )
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        } else {
          return response.json();
        }
      });
  }

  updateSurveyMap(workspaceGuid, surveyMapConfig) {
    return this.getAccessToken()
      .then((accessToken) => {
        const body = JSON.stringify(surveyMapConfig);
        return patchRequest(`/api/surveymaps/${workspaceGuid}`, accessToken, body);
      })
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        } else {
          return response.json();
        }
      });
  }
}
