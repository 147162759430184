import { getRequest, patchRequest } from '../utils/requestBuilder';

export default class ResponsesService {
  constructor(getAccessTokenFunc) {
    this.getAccessToken = getAccessTokenFunc;
  }

  getResponses(workspaceGuid, questionPublicKey) {
    return this.getAccessToken()
      .then((accessToken) =>
        getRequest(`/api/responses/${workspaceGuid}/${questionPublicKey}`, accessToken)
      )
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        } else {
          return response.json();
        }
      });
  }

  updateResponses(workspaceGuid, questionChoiceGuid, response) {
    return this.getAccessToken()
      .then((accessToken) =>
        patchRequest(
          `/api/responses/${workspaceGuid}/${questionChoiceGuid}`,
          accessToken,
          JSON.stringify(response)
        )
      )
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        } else {
          return response.json();
        }
      });
  }
}
