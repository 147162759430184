import { LogLevel, BrowserCacheLocation } from '@azure/msal-browser';
import { AUTHORITY, CLIENT_ID, KNOWN_AUTHORITIES, REDIRECT_URL } from '../openIdConfig';
export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: AUTHORITY,
    knownAuthorities: KNOWN_AUTHORITIES,
    redirectUri: REDIRECT_URL
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};
