import { guidGenerator } from '../../../utils/GuidHelper';
import PropTypes from 'prop-types';

class ModalButton {
  /**
   *
   * @param {string} buttonText
   * @param {string} buttonClasses
   * @param {function} buttonFunction
   * @returns Button component
   */
  constructor(buttonText, buttonClasses, buttonFunction) {
    return buttonComponent(buttonText, buttonClasses, buttonFunction);
  }
}

class ModalOkButton {
  /**
   *
   * @param {string} buttonText
   * @param {string} buttonClasses
   * @param {function} setShowModal
   * @returns Modal Ok button component
   */
  constructor(buttonClasses, setShowModal) {
    return new ModalCancelButton('Ok', buttonClasses, setShowModal);
  }
}

class ModalCancelButton {
  /**
   *
   * @param {string} buttonText
   * @param {string} buttonClasses
   * @param {function} setShowModal
   * @returns Modal cancel button component
   */
  constructor(buttonText, buttonClasses, setShowModal) {
    return buttonComponent(buttonText, buttonClasses, () => {
      setShowModal(false);
    });
  }
}

/**
 *
 * @param {string} buttonText
 * @param {string} buttonClasses
 * @param {function} buttonFunction
 * @returns Button component
 */
const buttonComponent = (buttonText, buttonClasses, buttonFunction) => {
  return (
    <button className={buttonClasses} onClick={buttonFunction}>
      {buttonText}
    </button>
  );
};

const Modal = ({
  showModal = false,
  setShowModal,
  previousModalSetShowModal,
  title,
  content,
  buttons,
  hideCloseButton,
  destroyPreviousModal,
  disableCloseOnOverlayClick,
  width = '80%',
  height = '80%'
}) => {
  if (!showModal) return <></>;

  previousModalSetShowModal && previousModalSetShowModal(false);

  //Adjusting content panel height
  let heightAmount = Number(height.replace('px', ''));
  let contentPanelHeight = 0;
  if (!isNaN(heightAmount) && heightAmount <= 375) {
    contentPanelHeight = ((heightAmount - 120) / heightAmount) * 100;
  }

  const modalId = 'modal_' + guidGenerator();
  let prevModal = '';

  const handleBackButtonClick = () => {
    //Show previous modal if it exists and is supposed to be shown.
    if (previousModalSetShowModal && !destroyPreviousModal) previousModalSetShowModal(true);

    //Remove current modal
    hideCurrentModal();
  };

  const hideCurrentModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div
        className="overlay"
        onClick={!disableCloseOnOverlayClick ? hideCurrentModal : () => {}}></div>
      <div
        id={modalId}
        className="o-modal"
        style={{
          width: width,
          height: height
        }}>
        <div className="c-modal_header cx-lang-translate">
          <span name="modalTitle">{title}</span>
          {previousModalSetShowModal && (
            <div className="c-modal_backbtn" onClick={handleBackButtonClick}>
              <input name="prevModalId" type="hidden" value={prevModal} />
              <svg
                xmlns="http://ww.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="12px"
                height="22px"
                viewBox="0 0 12 22"
                version="1.1">
                <defs>
                  <path
                    d="M12.6064,1.7077 L3.4144,10.8987 L12.6064,20.0927 C12.9974,20.4817 12.9974,21.1147 12.6064,21.5067 C12.2144,21.8967 11.5834,21.8967 11.1924,21.5067 L1.2934,11.6057 C0.9024,11.2167 0.9024,10.5837 1.2934,10.1917 L11.1924,0.2937 C11.5834,-0.0983 12.2144,-0.0983 12.6064,0.2937 C12.9974,0.6847 12.9974,1.3157 12.6064,1.7077"
                    id="path-1"
                  />
                </defs>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Icon/Arrow/Left" transform="translate(-1.000000, 0.000000)">
                    <mask id="mask-2" fill="white">
                      <use xlinkHref="#path-1" />
                    </mask>
                    <use id="ArrowLeft" fill="#FFFFFF" xlinkHref="#path-1" />
                  </g>
                </g>
              </svg>
            </div>
          )}

          {!hideCloseButton && (
            <div className="c-modal_close js-xbutton" onClick={hideCurrentModal}>
              <svg
                fill="#FFFFFF"
                height="16"
                viewBox="0 0 24 24"
                width="16"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </div>
          )}
        </div>
        <div
          id="idContentPanel"
          className="c-modal_body cx-lang-translate"
          style={{ height: contentPanelHeight > 0 ? contentPanelHeight + '%' : 'px' }}>
          {content}
          {/** Hide spinner after fetch call response data is shown */}
        </div>
        <div className="c-modal_footer cx-lang-translate">
          {buttons && buttons.length ? <hr /> : null}
          <div className="c-modal_buttons">
            {/* {React.createElement('button', buttons[0].props)} */}
            {buttons.map((button, i) => {
              const Type = button.type;
              return <Type {...button.props} key={modalId + '-' + i} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.any,
  buttons: PropTypes.array.isRequired,
  hideCloseButton: PropTypes.bool.isRequired,
  destroyPreviousModal: PropTypes.bool.isRequired,
  disableCloseOnOverlayClick: PropTypes.bool.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  previousModalSetShowModal: PropTypes.func
};

export default Modal;

export { Modal, ModalButton, ModalCancelButton, ModalOkButton };
