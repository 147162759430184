import { Outlet, useSearchParams } from 'react-router-dom';
import Login from './components/Login/Login';
import './App.css';
import { ProjectContext } from './context/ProjectContext';
import { useMemo } from 'react';

const App = () => {
  const [searchParams] = useSearchParams();

  const clientGuidQuery = searchParams.get('clientGuid');
  const workspaceGuidQuery = searchParams.get('workspaceGuid');

  const projectValues = useMemo(
    () => ({
      clientGuid: clientGuidQuery,
      workspaceGuid: workspaceGuidQuery
    }),
    [clientGuidQuery, workspaceGuidQuery]
  );

  return (
    <Login>
      <ProjectContext.Provider value={projectValues}>
        <header>
          <div className="header__app-title">Talos</div>
        </header>
        <div className="container">
          <Outlet />
        </div>
      </ProjectContext.Provider>
    </Login>
  );
};

export default App;
