import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function Dropdown({ id, name, options, defaultValue, onSelect }) {
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [defaultValue]);

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    onSelect(event);
  };

  return (
    <select
      id={id}
      name={name}
      className="form-control"
      onChange={handleOptionChange}
      value={selectedOption}>
      <option value=""> -- Select a option -- </option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func.isRequired
};

export default Dropdown;
