const isGuid = (value) => {
  const regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
  const match = regex.exec(value);
  return match !== null;
};

const isEmptyGuid = (value) => {
  const regex = /[0]{8}(?:-[0]{4}){3}-[0]{12}/i;
  const match = regex.exec(value);
  return match !== null;
};

const guidGenerator = () => {
  const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
};

const emptyGuidGenerator = () => {
  const g = '00000000-0000-0000-0000-000000000000';
  return g;
};

export { isGuid, isEmptyGuid, guidGenerator, emptyGuidGenerator };
