import App from '../App';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import Loading from '../components/Shared/Loading';
import { Index as QRConfig } from '../pages/QuestionsAndResponses/Index.jsx';
import Home from '../pages/Home/Home';
export const createRouter = () =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<App />}>
        <Route index="true" element={<Home />}></Route>
        <Route path="/signin-oidc" element={<Loading />} />
        <Route path="/config-qnr" element={<QRConfig />} />
      </Route>
    )
  );
