import PropTypes from 'prop-types';
import './H2Header.css';

function H2Header({ children }) {
  return (
    <div className="layoutHeader">
      <div className="layoutTitle">
        <h2 className="layoutLabel">{children}</h2>
      </div>
    </div>
  );
}

H2Header.propTypes = {
  children: PropTypes.node.isRequired
};

export default H2Header;
