import { Fragment, useState } from 'react';
import { Modal, ModalOkButton } from '../../components/Shared/Modal/Modal';

const Home = () => {
  //For testing the modal only. - The logic can later be removed
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const handleClickModal1 = () => showModal(setShowModal1);
  const handleClickModal2 = () => showModal(setShowModal2);
  const handleClickModal3 = () => showModal(setShowModal3);
  const handleClickModal4 = () => showModal(setShowModal4);
  const showModal = (setter) => {
    setter(true);
  };
  return (
    <Fragment>
      <Modal
        showModal={showModal1}
        setShowModal={setShowModal1}
        title="Informational Modal #1"
        content="The view has been updated successfully"
        buttons={[new ModalOkButton('', setShowModal1)]}
        hideCloseButton={false}
        destroyPreviousModal={true}
        disableCloseOnOverlayClick={false}
        width="400px"
        height="200px"
      />
      <Modal
        showModal={showModal2}
        setShowModal={setShowModal2}
        title="Informational Modal #2"
        content="The view has been updated successfully"
        buttons={[new ModalOkButton('', setShowModal2)]}
        hideCloseButton={true}
        destroyPreviousModal={true}
        disableCloseOnOverlayClick={false}
        width="400px"
        height="200px"
      />
      <Modal
        showModal={showModal3}
        setShowModal={setShowModal3}
        title="Informational Modal #3"
        content="The view has been updated successfully"
        buttons={[new ModalOkButton('', setShowModal3)]}
        hideCloseButton={false}
        destroyPreviousModal={true}
        disableCloseOnOverlayClick={true}
        width="400px"
        height="200px"
      />
      <Modal
        showModal={showModal4}
        setShowModal={setShowModal4}
        title="Informational Modal #4"
        content="The view has been updated successfully"
        buttons={[]}
        hideCloseButton={false}
        destroyPreviousModal={true}
        disableCloseOnOverlayClick={false}
        width="400px"
        height="200px"
      />
      <main>
        <div>
          <p>Good Day and Welcome to Talos, the ECOS Replacement</p>
        </div>
        {/* Temporary section bellow */}
        <div>
          <hr />
          <p>Testing Modal Component</p>
          <div>
            <p>Every way to close the modal is enabled</p>
            <button onClick={handleClickModal1}>Test Modal 1</button>
          </div>
          <div>
            <p>The X button is hidden</p>
            <button onClick={handleClickModal2}>Test Modal 2</button>
          </div>
          <div>
            <p>The overlay does NOT close the modal</p>
            <button onClick={handleClickModal3}>Test Modal 3</button>
          </div>
          <div>
            <p>No buttons in the modal</p>
            <button onClick={handleClickModal4}>Test Modal 4</button>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default Home;
