import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import DataGrid, { SelectCellFormatter } from 'react-data-grid';

import 'react-data-grid/lib/styles.css';

//Maps responses to rows for the grid
function createRows(responses, isOtherSpecifyEnabled) {
  const rows = [];
  responses.forEach((response) => {
    rows.push({
      ...response,
      isOtherSpecifyEnabled: isOtherSpecifyEnabled,
      isUpdated: false, // Flag used to check which rows have been updated
      errors: {
        responseText: ''
      }
    });
  });

  return rows;
}

function createColumns() {
  return [
    {
      key: 'responseText',
      name: 'Response Text',
      //using formatter to create an input field to allow the user to edit the response text
      //Columns also have a editor configuration option but it is not as customizable
      formatter: (p) => renderInput(p, 'responseText', p.row.errors.responseText)
    },
    {
      key: 'hidden',
      name: 'Hidden',
      width: 120,
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.active !== 1} //If a question is active the value is 1 if is inactive the value is 0
            onChange={(value) => onRowChange({ ...row, active: value ? 0 : 1, isUpdated: true })}
            isCellSelected={isCellSelected}
          />
        );
      }
    },
    {
      key: 'otherSpecify',
      name: 'Other Specify',
      width: 120,
      //using formatter to create a checkbox with the React-Data-Grid built in SelectCellFormatter component
      //to allow the user to edit the otherSpecify value
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.keyword === 4} // otherSpecify value is stored in the keyword property, if keyword id 4 then Other Specify is selected
            onChange={() => {
              onRowChange({ ...row, keyword: row.keyword === 4 ? 0 : 4, isUpdated: true });
            }}
            isCellSelected={isCellSelected}
            disabled={!row.isOtherSpecifyEnabled}
          />
        );
      }
    },
    { key: 'punch', name: 'Value', width: 100 },
    {
      key: 'altPunch',
      name: 'Alt Value',
      width: 100,
      formatter: (p) => (
        <input
          type="number"
          className="rdg-cell-input"
          value={p.row.altPunch === null ? '' : p.row.altPunch}
          onChange={(e) =>
            p.onRowChange({ ...p.row, altPunch: e.target.value, isUpdated: true }, true)
          }></input>
      )
    }
  ];
}

function renderInput(p, key, error) {
  const input = (
    <input
      className={'rdg-cell-input' + (error.length > 0 ? ' error-input' : '')}
      value={p.row[key]}
      onChange={(e) => p.onRowChange({ ...p.row, [key]: e.target.value, isUpdated: true }, true)}
      onBlur={(e) => {
        let error = e.target.value === '' ? 'Response Text is required' : '';
        if (error !== p.row.errors.responseText) {
          p.onRowChange({ ...p.row, errors: { ...p.row.errors, responseText: error } }, true);
        }
      }}></input>
  );
  const tooltip = (
    <a
      data-tooltip-id="QNRCenterPanelToolTip"
      data-tooltip-content={error}
      data-tooltip-place="top"
      data-tooltip-variant="error">
      {input}
    </a>
  );
  return error.length > 0 ? tooltip : input;
}

//Remove alternate values from all rows
function dropAlternateValues(setRows) {
  setRows((prevRows) => {
    return prevRows.map((row) => {
      return { ...row, altPunch: null };
    });
  });
}

//Optional but recommended for performance
function rowKeyGetter(row) {
  return row.cxChoiceGuid;
}

function ResponsesDataGrid({ responses, isOtherSpecifyEnabled, saveQuestionAndResponsesHandler }) {
  const [rows, setRows] = useState(createRows(responses, isOtherSpecifyEnabled));

  const columns = createColumns();

  if (rows.length > 0) {
    return (
      <Fragment>
        <DataGrid
          columns={columns}
          rows={rows}
          onRowsChange={setRows}
          className="rdg-light"
          rowKeyGetter={rowKeyGetter}
        />
        <div className="actionRow">
          <button className="btn dl-primary-button" onClick={() => dropAlternateValues(setRows)}>
            Drop Alternate Values
          </button>
        </div>
        <div className="button-row">
          <button
            className="btn dl-primary-button"
            onClick={() => saveQuestionAndResponsesHandler(rows)}>
            Update question and responses
          </button>
        </div>
      </Fragment>
    );
  }
  return <h3>No Response Found</h3>;
}

ResponsesDataGrid.propTypes = {
  responses: PropTypes.array.isRequired,
  isOtherSpecifyEnabled: PropTypes.bool.isRequired,
  saveQuestionAndResponsesHandler: PropTypes.func.isRequired
};

export default ResponsesDataGrid;
