import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../../components/Shared/DropDown.jsx';
import './RightPanel.css';
import H2Header from '../../../components/Shared/H2Header/H2Header.jsx';
import { displayFormatOptions } from '../../../utils/displayFormat';
import { filterBarSortModeOptions } from '../../../utils/filterBarSortMode';

/**
 *
 * @param question Active question selected from the question dropdown
 * @param handleQuestionPropChange Function that handles the changes made to the active question
 * @returns {JSX.Element}
 * @constructor
 */
function RightPanel({ activeQuestion, handleQuestionPropChange }) {
  const [questionLongText, setQuestionLongText] = useState('');
  const [questionShortText, setQuestionShortText] = useState('');
  const [questionDisplayStyle, setQuestionDisplayStyle] = useState('');
  const [questionSortModel, setQuestionSortModel] = useState('');

  useEffect(() => {
    if (Object.keys(activeQuestion).length > 0) {
      setQuestionLongText(activeQuestion.longText);
      setQuestionShortText(activeQuestion.shortText);
      setQuestionDisplayStyle(activeQuestion.displayStyle);
      setQuestionSortModel(activeQuestion.responseSortMode);
    }
  }, [activeQuestion]);

  return (
    <Fragment>
      <div className="right-header">
        <H2Header>Question Properties</H2Header>
      </div>
      <div className="right-sidebar">
        <div className="qnr-right-panel">
          <h3>Single Response</h3>
          <div className="form-group">
            <label htmlFor="questionText">Question text</label>
            <textarea
              className="form-control"
              id="questionText"
              name="questionText"
              value={questionLongText}
              onChange={(e) => handleQuestionPropChange('longText', e)}
              placeholder="Enter Question text"></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="shortText">Short text</label>
            <input
              type="text"
              className="form-control"
              id="shortText"
              name="shortText"
              value={questionShortText}
              onChange={(e) => handleQuestionPropChange('shortText', e)}
              placeholder="Enter short text"
            />
          </div>
          <div className="form-group">
            <label htmlFor="displayFormat">Display format</label>
            <Dropdown
              id={'displayFormat'}
              name={'displayFormat'}
              options={displayFormatOptions}
              defaultValue={questionDisplayStyle}
              onSelect={(e) => handleQuestionPropChange('displayStyle', e)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="filterBar">Filter bar sort</label>
            <Dropdown
              id={'filterBar'}
              name={'filterBar'}
              options={filterBarSortModeOptions}
              defaultValue={questionSortModel}
              onSelect={(e) => handleQuestionPropChange('responseSortMode', e)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

RightPanel.propTypes = {
  activeQuestion: PropTypes.object,
  handleQuestionPropChange: PropTypes.func.isRequired
};

export default RightPanel;
