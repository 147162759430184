import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';

import { useAccessToken } from '../../../hooks/useAccessToken';
import H2Header from '../../../components/Shared/H2Header/H2Header';
import NumericRangesDataGrid from './components/DataGrid/NumericRangesDataGrid';
import ResponsesDataGrid from './components/DataGrid/ResponsesDataGrid';

import './CenterPanel.css';
import ResponsesService from '../../../services/responsesService';
import ResponseTextService from '../../../services/responseTextService';
import BackDrop from '../../../components/Shared/BackDrop';

/**
 *
 * @param workspaceGuid Guid from the workspace
 * @param cxFieldGuid Public Key of a question
 * @param questionTitle The title of a question
 * @param activeQuestion Active question selected from the question dropdown
 * @param questionType Shows the according grid type is 3 then it shows the numeric ranges
 * @param saveQuestionHandler Function that saves the updated question properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
function CenterPanel({
  workspaceGuid,
  cxFieldGuid,
  questionTitle,
  activeQuestion,
  questionType,
  saveQuestionHandler
}) {
  const { getAccessToken } = useAccessToken();
  const responsesService = new ResponsesService(getAccessToken);
  const responseTextService = new ResponseTextService(getAccessToken);

  const [isLoading, setIsLoading] = useState(true);
  const [responses, setResponses] = useState([]);

  /**
   * This functions handles the save of the updated question and the updated responses.
   * This function gets passed to both the NumericRangesDataGrid and the ResponsesDataGrid components
   *
   * @param rows This are the rows created on the NumericRangesDataGrid and the ResponsesDataGrid components
   */
  const saveQuestionAndResponsesHandler = (rows) => {
    const rowHasError = rows.some((row) => row.errors.responseText !== '');

    if (rowHasError) {
      alert('Some rows have errors, please check them and try again');
    } else {
      const rowsToUpdate = rows.filter((row) => row.isUpdated);
      const updateResponsePromises = [];

      rowsToUpdate.forEach((row) => {
        updateResponsePromises.push(
          responsesService.updateResponses(workspaceGuid, row.cxChoiceGuid, row)
        );

        updateResponsePromises.push(
          responseTextService.updateResponseText(
            workspaceGuid,
            row.responseTextID,
            row.responseText
          )
        );
      });

      updateResponsePromises.push(saveQuestionHandler());

      Promise.all(updateResponsePromises)
        .then(() => {
          //TODO: show a successful message with the modal component
          alert('Question Updated!');
        })
        .catch(() => {
          //TODO: Show the error message with the modal component
          alert('Something failed');
        });
    }
  };

  useEffect(() => {
    if (cxFieldGuid && workspaceGuid) {
      setIsLoading(true);
      responsesService.getResponses(workspaceGuid, cxFieldGuid).then((data) => {
        setResponses(data);
        setIsLoading(false);
      });
    }
  }, [cxFieldGuid]);

  //TODO: Add loading spinner component in place of "Loading...", Story 87728
  const renderContent = () => {
    if (isLoading) {
      return <BackDrop></BackDrop>;
    } else if (questionType === 3) {
      return (
        <NumericRangesDataGrid
          responses={responses}
          saveQuestionAndResponsesHandler={saveQuestionAndResponsesHandler}
        />
      );
    } else {
      return (
        <ResponsesDataGrid
          responses={responses}
          isOtherSpecifyEnabled={activeQuestion.otherSpecify === 1}
          saveQuestionAndResponsesHandler={saveQuestionAndResponsesHandler}
        />
      );
    }
  };

  return (
    <Fragment>
      <div className="center-header">
        <H2Header>{questionTitle === '' ? 'Responses' : `Responses for ${questionTitle}`}</H2Header>
      </div>
      <section className="center-grid">
        <div className="qnr-center-panel">
          {renderContent()}
          <Tooltip id="QNRCenterPanelToolTip" className="tool-tip" />
        </div>
      </section>
    </Fragment>
  );
}

CenterPanel.propTypes = {
  questionTitle: PropTypes.string,
  workspaceGuid: PropTypes.string,
  cxFieldGuid: PropTypes.string,
  activeQuestion: PropTypes.object,
  questionType: PropTypes.number,
  saveQuestionHandler: PropTypes.func.isRequired
};

export default CenterPanel;
