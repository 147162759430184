export const filterBarSortModeOptions = [
  {
    value: 0,
    label: 'Default'
  },
  {
    value: 1,
    label: 'By Punch'
  },
  {
    value: 2,
    label: 'Alphabetical'
  }
];
