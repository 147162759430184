import CenterPanel from './CenterPanel/CenterPanel';
import './Index.css';
import RightPanel from './RightPanel/RightPanel';
import LeftPanel from './LeftPanel/LeftPanel';
import { useContext, useEffect, useState } from 'react';
import { ProjectContext } from '../../context/ProjectContext';
import { useAccessToken } from '../../hooks/useAccessToken';
import SurveyMapService from '../../services/surveyMapService';

export const Index = () => {
  const { getAccessToken } = useAccessToken();
  const { workspaceGuid } = useContext(ProjectContext);

  const [questionsList, setQuestionsList] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const surveyMapService = new SurveyMapService(getAccessToken);

  useEffect(() => {
    surveyMapService.getSurveyMap(workspaceGuid).then((data) => {
      setQuestionsList(data);
      setIsLoading(false);
    });
  }, []);

  /**
   * This function gets passed to the CenterPanelComponent where the main save happens
   *
   * @returns {Promise} Promise object that has the updated question that comes from the server response
   */
  const saveQuestionHandle = () => {
    surveyMapService.updateSurveyMap(workspaceGuid, activeQuestion).then((updatedQuestion) => {
      //We update the question that is in the questionList and we also update que questionList state
      const updatedQuestionList = questionsList.map((item) => {
        if (item.publicKey === updatedQuestion.publicKey) {
          return {
            ...item,
            ...updatedQuestion
          };
        }
        return item;
      });
      setQuestionsList(updatedQuestionList);
      return updatedQuestion;
    });
  };

  /**
   * This function sets the activeQuestion state when the user click on a question in the
   * question dropdown. This functions gets passed to the LeftPanel Component
   *
   * @param questionPublicKey the public key of the question clicked by the user
   */
  const handleQuestionOnClick = (questionPublicKey) => {
    const selectedQuestion = questionsList.find(
      (question) => question.publicKey === questionPublicKey
    );
    if (selectedQuestion) setActiveQuestion(selectedQuestion);
  };

  /**
   * This functions sets the activeQuestion when an user click on the up arrow icon
   * that is on the question panel. This functions gets passed to the LeftPanel Component
   *
   * @param questionPublicKey the public key of the question clicked by the user
   */
  const handlePreviousQuestion = (questionPublicKey) => {
    let currentQuestionIndex = questionsList.findIndex(
      (question) => question.publicKey === questionPublicKey
    );

    if (currentQuestionIndex === 0) {
      currentQuestionIndex = questionsList.length - 1;
      const desiredQuestion = questionsList[currentQuestionIndex];
      setActiveQuestion(desiredQuestion);
    } else if (currentQuestionIndex === -1) {
      const lastQuestion = questionsList[questionsList.length - 1];
      setActiveQuestion(lastQuestion);
    } else {
      const desiredQuestion = questionsList[currentQuestionIndex - 1];
      setActiveQuestion(desiredQuestion);
    }
  };

  /**
   * This functions sets the activeQuestion when an user click on the down arrow icon
   * that is on the question panel. This functions gets passed to the LeftPanel Component
   *
   * @param questionPublicKey the public key of the question clicked by the user
   */
  const handleNextQuestion = (questionPublicKey) => {
    const currentQuestionIndex = questionsList.findIndex(
      (question) => question.publicKey === questionPublicKey
    );

    if (currentQuestionIndex === questionsList.length - 1) {
      const desiredQuestion = questionsList[0];
      setActiveQuestion(desiredQuestion);
    } else {
      const desiredQuestion = questionsList[currentQuestionIndex + 1];
      setActiveQuestion(desiredQuestion);
    }
  };

  /**
   * This function updates one of activeQuestion properties that can be edited on the
   * Right Panel Component. This function gets passed to the RightPanel Component
   *
   * @param key The property we want to update from the activeQuestion
   * @param event The event that gets fired when a user interacts with one of the inputs
   */
  const handleQuestionPropChange = (key, event) => {
    setActiveQuestion({ ...activeQuestion, [key]: event.target.value });
  };

  return (
    <div className="container-qnr">
      <LeftPanel
        questions={questionsList}
        activeQuestion={activeQuestion}
        setActiveQuestion={setActiveQuestion}
        isLoading={isLoading}
        questionOnClickHandler={handleQuestionOnClick}
        nextQuestionClickHandler={handleNextQuestion}
        previousQuestionClickHandler={handlePreviousQuestion}
      />
      <CenterPanel
        questionTitle={activeQuestion.question ?? ''}
        workspaceGuid={workspaceGuid}
        cxFieldGuid={activeQuestion && activeQuestion.publicKey}
        activeQuestion={activeQuestion}
        saveQuestionHandler={saveQuestionHandle}
        questionType={activeQuestion.type}
      />
      <RightPanel
        activeQuestion={activeQuestion}
        handleQuestionPropChange={handleQuestionPropChange}
      />
    </div>
  );
};

export default Index;
