import { useCallback } from 'react';
import { ACCESS_TOKEN_SCOPES } from '../config/openIdConfig';
import { useAccount, useMsal } from '@azure/msal-react';

export const useAccessToken = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const getAccessToken = useCallback(
    () =>
      instance
        .acquireTokenSilent({
          scopes: ACCESS_TOKEN_SCOPES,
          account: account
        })
        .then((response) => response.accessToken),
    [account]
  );

  return { getAccessToken };
};
