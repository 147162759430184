import { patchRequest } from '../utils/requestBuilder';

export default class ResponseTextService {
  constructor(getAccessTokenFunction) {
    this.getAccessToken = getAccessTokenFunction;
  }

  updateResponseText(workspaceGuid, responseTextId, responseText) {
    return this.getAccessToken()
      .then((accessToken) => {
        const request = {
          id: responseTextId,
          responseText: responseText
        };
        return patchRequest(
          `/api/responseText/${workspaceGuid}/${responseTextId}`,
          accessToken,
          JSON.stringify(request)
        );
      })
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        } else {
          return response.json();
        }
      });
  }
}
