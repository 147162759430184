import TypeAhead from '../../../components/Shared/TypeAhead/TypeAhead';
import './LeftPanel.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import H2Header from '../../../components/Shared/H2Header/H2Header';
import BackDrop from '../../../components/Shared/BackDrop';
/**
 *
 * @param questions List of question retrieved from the survey database
 * @param activeQuestion Question selected from the questions dropdown or the TypeAhead component
 * @param setActiveQuestion Function that sets the activeQuestion state
 * @param isLoading Function used to indicate if the questions have been retrieved from the API or not
 * @param questionOnClickHandler Function that gets executed when user clicks on a question in the questionDropDown
 * @param previousQuestionClickHandler Function that gets executes when user clicks on the previous question button
 * @param nextQuestionClickHandler Function that gets executes when user clicks on the next question button
 * @returns {JSX.Element}
 * @constructor
 */
const LeftPanel = ({
  questions,
  activeQuestion,
  setActiveQuestion,
  isLoading,
  questionOnClickHandler,
  previousQuestionClickHandler,
  nextQuestionClickHandler
}) => {
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const [activeOption, setActiveOption] = useState();

  useEffect(() => {
    if (Object.keys(activeQuestion).length > 0) {
      const { publicKey, question } = activeQuestion;
      setCurrentSearchTerm(question);
      setActiveOption(publicKey);
    }
  }, [activeQuestion]);

  /**
   * This function sets the active question when an user click on a question that appears on the
   * TypeAhead dropdown. This function gets passed to the TypeAhead component
   *
   * @param e event that gets triggered when the users click on a question shows on the TypeAhead dropdown
   */
  const handleTypeAheadOnClick = (e) => {
    const desiredQuestion = questions.find((question) => question.publicKey === e.target.value);
    if (desiredQuestion) {
      setActiveQuestion(desiredQuestion);
      setCurrentSearchTerm(desiredQuestion.question);
    }
  };

  /**
   * This function sets the currentSearchTerm when the user types in the TypeAhead input.
   * This functions gets passed to the TypeAhead Component
   *
   * @param e  event that gets triggered when the users types on the the TypeAhead input
   */
  const handleTypeAheadOnChange = (e) => {
    setCurrentSearchTerm(e.target.value.toUpperCase());
  };

  if (isLoading) {
    return (
      <Fragment>
        <div className="left-header">
          <H2Header>Questions</H2Header>
        </div>
        <div className="questions-panel">
          <section>
            <BackDrop></BackDrop>
          </section>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="left-header">
        <H2Header>Questions</H2Header>
      </div>
      <div className="left-sidebar">
        <div className="questions-panel">
          <div id="panel-header">
            <div id="panel-title">
              <h4>Questions</h4>
              <div id="arrows-container">
                <button
                  id="previous-question"
                  className="bi bi-chevron-up arrow"
                  onClick={() => previousQuestionClickHandler(activeQuestion.publicKey)}></button>
                <button
                  id="next-question"
                  className="bi bi-chevron-down arrow"
                  onClick={() => nextQuestionClickHandler(activeQuestion.publicKey)}></button>
              </div>
            </div>
            <div id="panel-button-container">
              <button id="setup-default-questions">Setup Default Questions</button>
            </div>
          </div>
          <TypeAhead
            options={questions.map((opt) => ({
              value: opt.publicKey,
              text: opt.question,
              title: opt.longText
            }))}
            onActiveChange={handleTypeAheadOnClick}
            activeOption={activeOption}
            currentSearchTerm={currentSearchTerm}
            onSearchTermChange={handleTypeAheadOnChange}
          />
          <div className="questions-container">
            {questions.length > 0 &&
              questions.map((opt) => (
                <button
                  key={opt.publicKey}
                  className={
                    'questionlist-item' +
                    (opt.publicKey === activeQuestion.publicKey
                      ? ' questionlist-item-selected'
                      : '')
                  }
                  value={opt.publicKey}
                  onClick={() => questionOnClickHandler(opt.publicKey)}>
                  {opt.question}
                </button>
              ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

LeftPanel.propTypes = {
  questions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  questionOnClickHandler: PropTypes.func.isRequired,
  previousQuestionClickHandler: PropTypes.func.isRequired,
  nextQuestionClickHandler: PropTypes.func.isRequired,
  activeQuestion: PropTypes.object.isRequired,
  setActiveQuestion: PropTypes.func.isRequired
};

export default LeftPanel;
